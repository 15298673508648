(function ($, Drupal, generic, site) {
  'use strict';

  site.template = site.template || {};

  var redirectToCart = 0;
  var siteBagV1 = {
    formatter: $('.site-bag-v1'),
    template: 'site_bag_contents',
    is_mobile: typeof site.client.isMobile == 'undefined' ? '0' : site.client.isMobile,
    bagCount:
      typeof site.userInfoCookie == 'undefined'
        ? 0
        : parseInt(site.userInfoCookie.getValue('item_count')),
    fadeOutDelay: 2000
  };

  Drupal.behaviors.siteBagV1 = {
    get: function (key) {
      return siteBagV1[key];
    },

    set: function (key, val) {
      siteBagV1[key] = val;
    },

    loadBagContents: function (thisFormatter, showBagContents, resultData, callback) {
      var self = this;

      callback = typeof callback !== 'undefined' ? callback : function () {};

      // if we're sent a resultData object, repop the cart without making a secondary trans.get call
      if (typeof resultData !== 'undefined') {
        if (
          (resultData && Array.isArray(resultData) && resultData.length) ||
          Object.keys(resultData).length
        ) {
          self.repopCart(
            thisFormatter,
            resultData.trans_data,
            resultData,
            showBagContents,
            callback
          );
          // else make a trans.get call to refresh the cart state
        } else {
          // Load existing cart items
          generic.jsonrpc.fetch({
            method: 'trans.get',
            params: [
              {
                trans_fields: ['TRANS_ID', 'totals'],
                payment_fields: [],
                order_fields: ['items', 'samples', 'offerCodes']
              }
            ],
            onSuccess: function (r) {
              var cartData = r.getValue();

              self.repopCart(thisFormatter, cartData, resultData, showBagContents, callback);
            },
            onFailure: function (result) {
              if (redirectToCart) {
                $(location).attr('href', '/checkout/viewcart.tmpl');
              }
            }
          });
        }
      }
    },

    // repopulates the cart overlay with product data. Moved out of the loadBagContents onSuccess fn so we could refresh the
    // cart with given cart data (e.g., using data returned by addToBag rpc call) and not make loadBagContents make a second
    // redundant RPC call.
    // Note that cartData _and_ resultData are both required, because setBagTitle requires the data structured like resultData
    // whereas the rest of the repop code needs it structured like cartData, and that's a lot of annoying work to restructure all
    // those individual calls.
    repopCart: function (thisFormatter, cartData, resultData, showBagContents, callback) {
      var self = this;
      var undef;

      if (cartData === undef || cartData === null) {
        self.setBagCount(thisFormatter, self.get('bagCount') ? self.get('bagCount') : 0);
        self.showBagContents(thisFormatter, false);
        thisFormatter.find('.site-bag__contents-subtotal').hide();
        if (
          Drupal.settings.globals_variables &&
          Drupal.settings.globals_variables.enable_preorder
        ) {
          thisFormatter.find('.js-cart_overlay__preorderhead').hide();
          thisFormatter.find('.js-site-bag__contents-preorder-total').hide();
        }

        return;
      }

      var template = self.get('template');
      var cartItems =
        cartData.order.items !== undef ? cartData.order.items.concat(cartData.order.samples) : [];
      var displaySubline =
        Drupal.settings.globals_variables.display_sub_line !== undef
          ? Drupal.settings.globals_variables.display_sub_line
          : 0;
      var displayPriceWithTax =
        Drupal.settings.globals_variables.display_tax_price !== undef
          ? Drupal.settings.globals_variables.display_tax_price
          : 0;
      var templateData = {
        items: [],
        recommendations: [],
        display_sub_line: displaySubline,
        display_tax_price: displayPriceWithTax
      };

      templateData.display_bundle_info = 0;

      for (var i = 0, len = cartItems.length; i < len; i++) {
        var cartItem = cartItems[i];

        templateData.items[i] = {};
        for (var key in cartItem) {
          // Normalize key names in cartItem object. 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
          if (cartItem.hasOwnProperty(key)) {
            templateData.items[i][key.replace(/\./, '_')] = cartItem[key];
          }
        }
        if (
          templateData.items[i].hasOwnProperty('isKitItem') &&
          templateData.items[i].isKitItem === 1
        ) {
          for (var ii = 0, lenlen = templateData.items[i].items.length; ii < lenlen; ii++) {
            var kitItem = templateData.items[i].items[ii];

            for (var kitKey in kitItem) {
              if (kitItem.hasOwnProperty(kitKey)) {
                templateData.items[i].items[ii][kitKey.replace(/\./, '_')] = kitItem[kitKey];
              }
            }
          }
        }
        if (
          templateData.items[i].hasOwnProperty('isBundleItem') &&
          templateData.items[i].isBundleItem
        ) {
          templateData.display_bundle_info = 1;
        }
      }

      templateData.enablePreorder =
        Drupal.settings.globals_variables.enable_preorder !== undef
          ? Drupal.settings.globals_variables.enable_preorder
          : 0;
      templateData.hasPreorderItems =
        cartData.preorder && cartData.preorder.items_count !== undef ? 1 : 0;
      templateData.hasRecommendations = cartData.hasRecommendations;
      templateData.recommendations = cartData.recommendations;
      templateData.formattedSubtotal = cartData.formattedSubtotal;
      // Extract the untaxed sub total if it's available, otherwise fall back to the taxed total
      // All locales I've tested that don't have this value, don't have tax so would use this value under the old calculation
      templateData.formattedSubtotalNoTax =
        cartData.formattedSubtotalNoTax || cartData.formattedSubtotal;
      // Taxed sub total
      templateData.formattedSubtotalWithTax = cartData.formattedSubtotal;

      templateData.items_count = cartData.items_count;

      var site_bag_contents = site.template.get({
        name: template,
        data: templateData
      });

      // replace contents with new rendered template
      thisFormatter.find('.site-bag__contents').remove();
      thisFormatter
        .append(site_bag_contents)
        .promise()
        .done(function () {
          self.loadPreOrderBagContents(cartData);

          // cart shade tooltip
          thisFormatter.find('.cart-product-kit-shade').tooltipster({
            arrow: false,
            contentAsHTML: true,
            hideOnClick: true,
            theme: 'mac-tooltip',
            offsetX: 10
          });

          thisFormatter
            .find('.js-shipping-overlay')
            .once()
            .click(function () {
              $(window).trigger('window:show_shipping_overlay');

              return false;
            });

          if (showBagContents === true) {
            self.setBagTitle(thisFormatter, true, resultData);
            self.showBagContents(thisFormatter, true);
          } else {
            self.setBagTitle(thisFormatter, false, []);
          }

          self.setBagCount(thisFormatter, templateData.items_count);
        });

      $(window).trigger('macproMakeVisible');

      callback();
    },

    showBagContents: function (thisFormatter, fadeOut) {
      var self = this;

      thisFormatter.addClass('active');
      if (site
        && site.track
        && (thisFormatter.closest('.site-header-formatter__cart').length > 0
        || thisFormatter.closest('.site-header__tools').length > 0)) {
        site.track.cartOverlay();
      }
      var $overlay_content = thisFormatter.find('.js-site_bag_contents');
      var $triggerInput = thisFormatter.find('.js-gnav-util-trigger');
      var $triggerReset = thisFormatter.find('.js-gnav-util-trigger-reset');

      site.restrict_navigation($overlay_content);

      if (Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr20) {
        $triggerInput.prop('checked', true);
      }

      thisFormatter.on('clickoutside', function () {
        thisFormatter.removeClass('active').off('clickoutside');
        if (Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr20) {
          $triggerReset.trigger('click');
        }
      });

      thisFormatter
        .find('.site-bag__contents-close')
        .once()
        .click(function (e) {
          e.preventDefault();
          thisFormatter.children('.js-site_bag_link').attr('aria-expanded', 'false').focus();
          thisFormatter.trigger('clickoutside');
        });

      if (fadeOut === true) {
        thisFormatter.find('.site-bag__contents-subtotal').hide();

        if (
          Drupal.settings.globals_variables &&
          Drupal.settings.globals_variables.enable_preorder
        ) {
          thisFormatter.find('.js-cart_overlay__preorderhead').hide();
          thisFormatter.find('.js-site-bag__contents-preorder-total').hide();
        }
        thisFormatter.find('.site-bag__contents-recommendations').hide();
        thisFormatter.find('.site-bag__contents-products').attr('style', '');
        var timeout = self.get('fadeOutDelay');
        var onTimeout = function () {
          thisFormatter.find('.site-bag__contents').fadeOut(400, function () {
            if (Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr20) {
              $triggerReset.trigger('click');
            }
            thisFormatter.removeClass('active').off('clickoutside');
            $(this).attr('style', '');
            $(window).trigger('window:close_bag');
          });
        };
        var timer = setTimeout(onTimeout, timeout);

        thisFormatter.find('.site-bag__contents').hover(
          function () {
            clearTimeout(timer);
          },
          function () {
            timer = setTimeout(onTimeout, timeout);
          }
        );
      } else {
        $('.site-bag__contents-subtotal').show();

        if (
          Drupal.settings.globals_variables &&
          Drupal.settings.globals_variables.enable_preorder
        ) {
          $('.js-cart_overlay__preorderhead').show();
          $('.js-site-bag__contents-preorder-total').show();
        }

        $('.site-bag__contents-recommendations').show();
        self.setOverlayHeight(thisFormatter);
      }
      self.initRecommendations(thisFormatter);
    },

    setBagTitle: function (thisFormatter, showAltTitle, resultData) {
      var bagContents = thisFormatter.find('.site-bag__contents');
      var cartProducts = thisFormatter.find('.site-bag__contents-products .cart-product');

      if (showAltTitle === true) {
        cartProducts.hide();
        $.each(resultData.ac_results, function (index, cartDataItem) {
          if (
            typeof cartDataItem.result.CARTITEM !== 'undefined' &&
            typeof cartDataItem.result.CARTITEM['sku.SKU_BASE_ID'] !== 'undefined'
          ) {
            var resultSkuId = cartDataItem.result.CARTITEM['sku.SKU_BASE_ID'];

            thisFormatter
              .find(
                '.site-bag__contents-products .cart-product[data-sku_base_id="' +
                  resultSkuId +
                  '"]:first'
              )
              .show();
          }
        });
        bagContents.find('.site-bag__contents-title').hide();
        bagContents.find('.site-bag__contents-title__alt').show();
      } else {
        cartProducts.show();
        bagContents.find('.site-bag__contents-title').show();
        bagContents.find('.site-bag__contents-title__alt').hide();
      }
    },

    setBagCount: function (thisFormatter, bagCount) {
      var self = this;

      thisFormatter.find('.site-bag__link-count').html(bagCount);
      self.set('bagCount', bagCount);
    },

    showBagCount: function (thisFormatter) {
      var self = this;
      var bagCount = self.get('bagCount');

      bagCount = isNaN(bagCount) ? 0 : bagCount;
      self.setBagCount(thisFormatter, bagCount);
    },

    loadPreOrderBagContents: function (cartData) {
      var enablePreOrder =
        typeof Drupal.settings.globals_variables.enable_preorder !== 'undefined'
          ? Drupal.settings.globals_variables.enable_preorder
          : 0;

      if (!enablePreOrder) {
        return '';
      }

      if (!site.templates['site_bag_preorder_contents']) {
        return '';
      }

      var undef;
      var displaySubline =
        Drupal.settings.globals_variables.display_sub_line !== undef
          ? Drupal.settings.globals_variables.display_sub_line
          : 0;
      var displayPriceWithTax =
        Drupal.settings.globals_variables.display_tax_price !== undef
          ? Drupal.settings.globals_variables.display_tax_price
          : 0;
      var templateData = {
        has_preorder: 0,
        items: 0,
        preorder_items: [],
        display_sub_line: displaySubline,
        display_tax_price: displayPriceWithTax
      };
      var cartItems =
        cartData.order.items !== undef ? cartData.order.items.concat(cartData.order.samples) : [];
      var preOrderData = cartData.preorder ? cartData.preorder : {};

      templateData['items'] = cartItems;
      templateData['has_preorder'] = preOrderData.items_count;
      if (preOrderData.items_count > 0) {
        templateData['preorder_items'] = preOrderData.items;
        templateData.formattedTotalPriceWithTax = preOrderData.totals.formattedTotalPriceWithTax;
        templateData.formattedTotalPrice = preOrderData.totals.formattedTotalPrice;
        var precartItems = preOrderData.items;

        for (var j = 0, plen = precartItems.length; j < plen; j++) {
          var precartItem = precartItems[j];

          templateData.preorder_items[j] = {};
          for (var pkey in precartItem) {
            // Normalize key names in precartItem object. 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
            if (precartItem.hasOwnProperty(pkey)) {
              templateData.preorder_items[j][pkey.replace(/\./, '_')] = precartItem[pkey];
            }
          }
          if (
            templateData.preorder_items[j].hasOwnProperty('isKitItem') &&
            templateData.preorder_items[j].isKitItem === 1
          ) {
            for (
              var jj = 0, plenlen = templateData.preorder_items[j].preorder_items.length;
              jj < plenlen;
              jj++
            ) {
              var pkitItem = templateData.preorder_items[j].preorder_items[jj];

              for (var pkitKey in pkitItem) {
                if (pkitItem.hasOwnProperty(pkitKey)) {
                  templateData.preorder_items[j].preorder_items[jj][pkitKey.replace(/\./, '_')] =
                    pkitItem[pkitKey];
                }
              }
            }
          }
        }
      }

      var html = site.template.get({
        name: 'site_bag_preorder_contents',
        data: templateData
      });

      return $(html).appendTo('.js-site-bag-contents-products');
    },

    setOverlayHeight: function ($thisFormatter) {
      var $bagContents = $thisFormatter.find('.js-site_bag_contents');
      // set height of entire overlay to window height, less gnav offset
      var windowHeight = $(window).height();
      var overlayOffsetTop = $bagContents.parent().offset().top;
      var overlayHeight = Drupal.settings.globals_variables.cr21
        ? windowHeight - $('.js-site-header').height()
        : windowHeight - overlayOffsetTop - $('.site-banner__is-open .site-banner').height();

      $bagContents.height(overlayHeight);

      // set height of product list to available space
      var headerHeight = $bagContents.find('.site-bag__contents-header').outerHeight(false);
      var footerHeight = $bagContents.find('.site-bag__contents-footer').outerHeight(false);
      var $productsContainer = $bagContents.find('.js-site-bag-contents-products');
      var recommendationsHeight = 0;

      if ($bagContents.find('.site-bag__contents-recommendations').is(':visible')) {
        recommendationsHeight = $bagContents
          .find('.site-bag__contents-recommendations')
          .outerHeight(false);
      }

      var productsHeight = overlayHeight - headerHeight - footerHeight - recommendationsHeight;

      $productsContainer.height(productsHeight);
    },

    initRecommendations: function (thisFormatter) {
      var self = this;
      var recommendations = thisFormatter.find('.site-bag__contents-recommendations');

      if (recommendations.is(':visible')) {
        var productsContainer = thisFormatter.find('.site-bag__contents-products');
        var productsHeight = productsContainer.outerHeight(false);
        var recommendationsHeight = recommendations.outerHeight(false);
        var originalHeight = recommendationsHeight;
        var recommendationsToggle = recommendations.find(
          '.site-bag__contents-recommendations__title'
        );
        var carouselContainer = recommendations.find(
          '.site-bag__contents-recommendations-carousel'
        );
        var carousel = carouselContainer.find('> ul');
        var appendArrows = carouselContainer.find(
          '.site-bag__contents-recommendations-carousel__controls'
        );
        var count = appendArrows.find('.count');
        var items = carousel.find('.carousel-slide').length;

        recommendationsToggle.once().click(function () {
          if (recommendations.hasClass('active')) {
            recommendations.removeClass('active');
            carousel.unslick();
            var oldHeight = productsHeight - originalHeight + recommendationsHeight;

            productsContainer.animate(
              {
                height: oldHeight
              },
              300,
              function () {
                productsHeight = productsContainer.outerHeight(false);
              }
            );
          } else {
            recommendations.addClass('active');

            if (items === 1) {
              appendArrows.hide();
            }

            var isRTL = site.direction.isRTL && Drupal?.settings?.forceSlickRTL;
            // swipe/drag direction is opposite when in RTL
            // so better to just disable it until a fix is provided in the library
            var swipe = isRTL ? false : true;
            var draggable = swipe;

            carousel.slick({
              rtl: isRTL,
              swipe: swipe,
              draggable: draggable,
              appendArrows: appendArrows,
              autoplay: false,
              fade: true,
              slide: '.carousel-slide',
              slidesToShow: 1,
              slidesToScroll: 1,
              accessibility: true,
              speed: 300,
              onInit: function () {
                recommendationsHeight = recommendations.outerHeight(false);
                count.html('1/' + items);
              },
              onAfterChange: function () {
                var currentSlide = carousel.slickCurrentSlide() + 1;

                count.html(currentSlide + '/' + items);
              }
            });

            var newHeight = productsHeight + originalHeight - recommendationsHeight;

            productsContainer.animate(
              {
                height: newHeight
              },
              300,
              function () {
                productsHeight = productsContainer.outerHeight(false);
              }
            );

            carousel
              .find('.js-add-to-bag')
              .once()
              .click(function () {
                var product = $(this).closest('.cart-product');
                var skuBaseId = product.data('sku_base_id');
                var args = { skuBaseId: skuBaseId };

                prodcat.ui.addToCart(args);

                return false;
              });
          }
        });
      }
    },

    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      function fixedEncodeURIComponent(str) {
        var shadeName = str.split('shade/')[1];

        return encodeURIComponent(shadeName).replace(/[!'()*]/g, function (c) {
          return '%' + c.charCodeAt(0).toString(16);
        });
      }
      this.attached = true;
      var self = this;
      var formatter = self.get('formatter');

      formatter.each(function () {
        var thisFormatter = $(this);
        var $triggerReset = thisFormatter.find('.js-gnav-util-trigger-reset');

        self.showBagCount(thisFormatter);
        // Load/Create/Show overlay on click
        thisFormatter
          .find('.js-site_bag_link')
          .once()
          .click(function (e) {
            // Prevent going to shopping bag on click.
            if (parseInt(self.get('is_mobile')) === 0 || $('.js-custom-kit').length) {
              e.preventDefault();
              $(this).attr('aria-expanded', 'true');
              if (!thisFormatter.hasClass('active')) {
                redirectToCart = 1;
                self.loadBagContents(thisFormatter, false, [], function () {
                  self.setBagTitle(thisFormatter, false, []);
                  self.showBagContents(thisFormatter, false);
                  thisFormatter
                    .find('.js-cart-product-name a, .js-cart-product a')
                    .each(function () {
                      var $this = $(this),
                        href = $this.attr('href'),
                        shadeNameBefore = href.split('shade/')[0],
                        encodedShadeName = fixedEncodeURIComponent(
                          href.toString().split(' ').join('_')
                        );

                      $this.attr('href', shadeNameBefore + 'shade/' + encodedShadeName);
                    });
                });
              } else {
                thisFormatter.removeClass('active');
                if (Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr20) {
                  $triggerReset.trigger('click');
                }
              }
            } else if (
              parseInt(self.get('is_mobile')) === 1 &&
              Drupal.settings.globals_variables &&
              Drupal.settings.globals_variables.cr20
            ) {
              e.preventDefault();
              window.location.href = this.href;
            }
          });
        // Hide overlay on resize
        $(window).resize(
          _.debounce(function () {
            if (thisFormatter.hasClass('active')) {
              thisFormatter.trigger('clickoutside');
              thisFormatter.children('.js-site_bag_link').attr('aria-expanded', 'false');
            }
          }, 50)
        );

        // gnavdata.js will trigger 'gnavdata_success'
        $(window).on('gnavdata_success', function () {
          // get the bag count from the cookie initially
          self.showBagCount(thisFormatter);
        });

        // repopulate bag contents and show overlay
        $(window).on('addToCart.success', function (e, resultData) {
          self.loadBagContents(thisFormatter, true, resultData);
        });

        // repopulate bag contents
        $(window).on('window:refresh_bag_contents', function () {
          self.loadBagContents(thisFormatter, false, []);
        });

        // user added something to their cart so update the gnav bag count
        $(window).on('window:update_bag_count', function (e, bagCount, force) {
          // get the bag count from the transaction details
          if (force || self.get('bagCount') !== bagCount) {
            self.setBagCount(thisFormatter, bagCount);
          }
        });

        $(window).on('window:show_shipping_overlay', function () {
          var $activeElement = $(document.activeElement);
          var content = thisFormatter.find('.site-bag__overlay-content');

          if (content.length > 0) {
            generic.overlay.launch({
              content: content.clone(true),
              includeBackground: true,
              includeCloseLink: true,
              initialHeight: 0,
              height: 0,
              width: '50%',
              onComplete: function () {
                $(window).trigger('macproMakeVisible');
                $(this).colorbox.resize();
              },
              onClosed: function () {
                $activeElement.focus();
              }
            });
          }
        });

        $('.js-shipping-overlay')
          .once()
          .click(function () {
            $(window).trigger('window:show_shipping_overlay');

            return false;
          });

        // cart shade tooltip
        $('#viewcart-panel').find('.cart-product-kit-shade').tooltipster({
          arrow: false,
          contentAsHTML: true,
          hideOnClick: true,
          theme: 'mac-tooltip',
          offsetX: 10
        });
      });
    },

    attached: false
  };
})(jQuery, Drupal, window.generic || {}, window.site || {});
